import React from 'react'
import { Link } from 'gatsby'
import SEO from '~/components/seo'
import { Col, ColText, TwoColContainer } from '../utils/styles'
import Img from 'gatsby-image'

const ContactPage = ({ data }) => (
  <>
    <SEO
      title="Contact"
      keywords={[`contact`, `returns`, `flower paintings`]}
    />
    <TwoColContainer>
      <Col>
        <Img fluid={data.file.childImageSharp.fluid} />
      </Col>
      <ColText>
        <h2>Contact</h2>
        <p>
          If you require any further information please don't hesitate to
          contact us at vintageflowerpaintings@gmail.com
        </p>
        <p>
          While we hope that your order is everything you hoped for if you are
          unhappy with your purchase you may send it back within 7 days and your
          money will be refunded less the shipping costs. Please get in contact
          if you would like to discuss your order.
        </p>
      </ColText>
    </TwoColContainer>
  </>
)

export const pageQuery = graphql`
  {
    file(name: { eq: "contact-image" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ContactPage
